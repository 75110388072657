/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
import PermissionAlert from 'components/popup/alert/permission/PermissionAlert';
import FloorChangedPopup from 'components/popup/floor-changed/FloorChangedPopup';
import NaviPopup from 'components/popup/navi/NaviPopup';
import MyParkingLocationPopup from 'components/popup/parking/my-parking-location/MyParkingLocationPopup';
import NaviToMyParkingLocationPopup from 'components/popup/parking/navi-to-my-parking-location/NaviToMyParkingLocationPopup';
import SaveMyParkingLocationPopup from 'components/popup/parking/save-my-parking-location/SaveMyParkingLocation';
import DetailTenantPopup from 'components/popup/tenant/detail/DetailTenantPopup';
import PopupTenantList from 'components/popup/tenant/list/PopupTenantList';
import SimpleTenantPopup from 'components/popup/tenant/simple/SimpleTenantPopup';
import TransportPopup from 'components/popup/transport/TransportPopup';
import { ME_DEFAULT } from 'data/image';
import { LOCAL_STORAGE_MOTION_PERMISSION_KEY, LOCAL_STORAGE_PARKING_LOCATION_KEY } from 'data/localStorageKey';
import useStats from 'hooks/api/stats/useStats';
import useMapAppend from 'hooks/map/event/useMapAppend';
import useMapClick from 'hooks/map/event/useMapClick';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import useMapLocation from 'hooks/map/trigger/useMapLocation';
import useMapMotionPermission from 'hooks/map/trigger/useMapMotionPermission';
import useMapNavigation from 'hooks/map/trigger/useMapNavigation';
import useMapTracking from 'hooks/map/trigger/useMapTracking';
import useMapTransport from 'hooks/map/trigger/useMapTransport';
import useModal from 'hooks/modal/useModal';
import useUrlType from 'hooks/params/useUrlType';
import MapDraw from 'map/MapDraw';
import { clearMap, clearMyLocation } from 'map/control/common/clear';
import { showMyLocationNoAni } from 'map/control/myLocation';
import { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import useMapActionStore from 'store/action';
import useFacilityStore from 'store/facility';
import useFloorStore from 'store/floor';
import useLoadingStore from 'store/loading';
import useModalStore from 'store/modal';
import useNaviStore from 'store/navi';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTenantStore from 'store/tenant';
import useTrackingStore from 'store/tracking';
import useValidStore from 'store/valid';
import { PermissionType } from 'types/common/permission.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { iOSOver13 } from 'util/permission/requestDeviceMotionPermission';
import styles from './MapContainer.module.scss';
import DabeeoLogo from './logo/DabeeoLogo';
import TrackingModeButton from './tracking-mode/TrackingModeButton';

const Map = () => {
  // store
  const tenantStore = useTenantStore();
  const loadingStore = useLoadingStore();
  const floorStore = useFloorStore();
  const mapActionStore = useMapActionStore();
  const popupStore = usePopupStore();
  const facilityStore = useFacilityStore();
  const modalStore = useModalStore();
  const validStore = useValidStore();
  const naviStore = useNaviStore();
  const trackingStore = useTrackingStore();
  const pointStore = usePointStore();

  // ref
  const mapRef = useRef<HTMLDivElement>(null);
  const parkingPopupCloseTimer = useRef<NodeJS.Timeout | null>(null);

  // hooks
  const { openModal, closeModal, visibleModal } = useModal();
  useMapClick({ openModal, closeModal });
  const { onRequestMotionPermission } = useMapMotionPermission();
  const { postDeviceMotionAttached } = useStats();

  // 지도 append
  useMapAppend({ mapRef });
  // direction tracking
  const { onDirectionTracking, fixCurrentRotation, switchActionByTrackingType } = useMapDirection();
  // indoor tracking
  useMapTracking();

  // URL type별 action 사용
  useUrlType();
  // 위치확인
  useMapLocation();
  // 길찾기
  useMapNavigation();
  // 이동수단 선택
  useMapTransport();

  // variables
  // 주차 위치
  const storageParkingLocation = localStorage.getItem(LOCAL_STORAGE_PARKING_LOCATION_KEY);
  const myParkingLocation: TenantWithPointId = storageParkingLocation ? JSON.parse(storageParkingLocation || '') : '';
  // 모션 동의 여부
  const localStorageMotionPermission = localStorage.getItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY);
  const isMotionPermissionGranted =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'granted';
  const isMotionPermissionDenied =
    localStorageMotionPermission && (localStorageMotionPermission as PermissionType) === 'denied';

  const onClickMap = () => {
    clearMap();
    clearMyLocation();
    closeModal();
    modalStore.closeAllModal();
    naviStore.clearNaviRoutes();
    mapActionStore.resetMapActions();
  };

  /**
   * 모션 권한 alert 을 닫는다
   */
  const onClosePermissionAlert = () => {
    trackingStore.setOpenPermissionAlert(false);
  };

  // cleanup
  useEffect(() => {
    loadingStore.setLoadingMap(true);

    return () => {
      mapActionStore.resetMapActions();
      facilityStore.resetCurrentFacilityId();
      floorStore.setCurrentFloorId(MapDraw.machineFloorId);

      clearMap();
      MapDraw.cleanup();
    };
  }, []);

  // parkingPopupCloseTimer 초기화
  useEffect(() => {
    return () => {
      if (parkingPopupCloseTimer.current) {
        clearTimeout(parkingPopupCloseTimer.current);
      }
    };
  }, []);

  /**
   * 모션 권한을 거절했을 경우
   * trackingType 을 OFF로 변경한다
   * 내위치마커 기본으로 보여준다
   */
  const denyDirectionTracking = async () => {
    localStorage.setItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY, 'denied');
    trackingStore.setTrackingType('OFF');
    await postDeviceMotionAttached(false);
    await showMyLocationNoAni(ME_DEFAULT);
  };

  /**
   * ios 13+ 일 경우 모션 접근을 허용해야 한다
   * 나머지는 바로 trackingType ON으로 변경한다
   */
  const requestMotionPermission = async () => {
    if (DeviceMotionEvent && iOSOver13 && !localStorageMotionPermission) {
      trackingStore.setOpenPermissionAlert(true);

      return;
    }

    localStorage.setItem(LOCAL_STORAGE_MOTION_PERMISSION_KEY, 'granted');
    await postDeviceMotionAttached(true);
    // ! 지도 회전 제거
    // await onDirectionTracking();
    await fixCurrentRotation();
  };

  /**
   * 지도에 진입했을 때
   * 기존에 denied 되어있었다면 trackingType OFF 해준다
   *
   * 나머지는 direction tracking 을 실행한다 =>
   *
   * 만약 localStorageMotionPermission 이 없을 경우에는 새로 permission을 요구한다
   * isMotionPermissionGranted 일 경우에는 trackingType 에 따라 구현이 달라야한다
   */
  useEffect(() => {
    if (!loadingStore.isLoadingMap) {
      // if (!mapActionStore.actions.location) {
      if (!mapActionStore.actions.indoorTracking) {
        if (isMotionPermissionDenied) {
          denyDirectionTracking();
          return;
        }

        if (isMotionPermissionGranted) {
          switchActionByTrackingType(trackingStore.trackingType);
          postDeviceMotionAttached(true);
          return;
        }

        // localStorageMotionPermission 이 없을 경우에는 모션 접근을 허용해야 한다
        requestMotionPermission();
      }
      // }
    }
  }, [loadingStore.isLoadingMap]);

  /**
   * 현재 층이거나 모션이 동의 되었을 경우만
   * tracking mode 버튼 노출한다
   */
  const isVisibleTrackingModeBtn = MapDraw.machineFloorId === floorStore.currentFloorId && isMotionPermissionGranted;

  return (
    <>
      {/* 모션 권한 허용 알림창 */}
      {trackingStore.isOpenPermissionAlert && (
        <PermissionAlert
          isOpenPopup={trackingStore.isOpenPermissionAlert}
          onClickConfirm={async () => {
            await onRequestMotionPermission();
            onClosePermissionAlert();
          }}
          onClose={onClosePermissionAlert}
        />
      )}

      <div
        onClick={onClickMap}
        className={`${styles.map} ${pointStore.points.length > 1 ? styles.multi_points : styles.single_point}`}
        ref={mapRef}
      >
        {/* {trackingStore.mapRotation} */}

        {/* 다비오 로고 */}
        <DabeeoLogo />

        {/* 방향 GPS ON/FIXED/OFF */}
        {isVisibleTrackingModeBtn && <TrackingModeButton />}

        {/* 층 변경 팝업 */}
        {popupStore.openFloorChangedPopup && <FloorChangedPopup />}
      </div>

      {/* tracking result */}
      {/* <div className={styles.tracking_wrapper}>
          {trackings.map(({ title, value }) => (
            <div className={styles.tracking_result} key={title}>
              <span className={styles.title}>{title}</span>
              <span className={styles.value}>{value}</span>
            </div>
          ))}
        </div> */}

      {/* <div className={styles.tracking}>{trackingMoveResult.length || 'tracking length'}</div> */}

      {/* 테넌트 팝업 */}
      {!loadingStore.isLoadingMap && tenantStore.currentTenant && (
        <Transition timeout={500} in={visibleModal} unmountOnExit>
          {tenantStore.currentTenant.popupType === 'SMALL' ? (
            <SimpleTenantPopup
              closeModal={() => {
                clearMap();
                closeModal();
              }}
              isOpenPopup={visibleModal}
            />
          ) : (
            <DetailTenantPopup
              closeDetailPopup={() => {
                clearMap();
                closeModal();
              }}
              isOpenPopup={visibleModal}
            />
          )}
        </Transition>
      )}

      {/* 내 주차위치 지정 */}
      {
        // ! 시연용 워크스페이스 분기처리
        validStore.isPocWorkspace && !loadingStore.isLoadingMap && (
          <Transition timeout={300} unmountOnExit in={modalStore.openParkingModal}>
            <SaveMyParkingLocationPopup
              isOpenPopup={modalStore.openParkingModal}
              onClosePopup={() => {
                // 공유하기 팝업이 켜져있을 경우에는 닫지 않는다
                if (modalStore.openShareModal) {
                  return;
                }

                modalStore.setOpenParkingModal(false);
                clearMap();
              }}
              parkingPopupCloseTimer={parkingPopupCloseTimer}
            />
          </Transition>
        )
      }

      {/* 내 주차위치 확인 팝업 */}
      {
        // ! 시연용 워크스페이스 분기처리
        validStore.isPocWorkspace && !loadingStore.isLoadingMap && (
          <Transition timeout={300} unmountOnExit in={modalStore.openMyParkingModal}>
            <MyParkingLocationPopup
              isOpenPopup={modalStore.openMyParkingModal}
              onClosePopup={() => {
                // 공유하기 팝업이 켜져있을 경우에는 닫지 않는다
                if (modalStore.openShareModal) {
                  return;
                }

                modalStore.setOpenMyParkingModal(false);
                clearMap();
              }}
              parkingPopupCloseTimer={parkingPopupCloseTimer}
            />
          </Transition>
        )
      }

      {/* 주차위치로 길찾기 팝업 */}
      {
        // ! 시연용 워크스페이스 분기처리
        validStore.isPocWorkspace && !loadingStore.isLoadingMap && myParkingLocation && (
          <Transition timeout={300} unmountOnExit in={modalStore.openParkingNaviModal}>
            <NaviToMyParkingLocationPopup
              isOpenPopup={modalStore.openParkingNaviModal}
              onClosePopup={() => {
                modalStore.setOpenParkingNaviModal(false);
                clearMap();
              }}
            />
          </Transition>
        )
      }

      {/* 다중 tenant 목록 팝업 */}
      {modalStore.openTenantListModal && (
        <PopupTenantList
          closeTenantList={() => {
            modalStore.setOpenTenantListModal(false);
            tenantStore.resetCurrentTenantArr();
          }}
          openModal={openModal}
        />
      )}

      {/* 이동수단 선택 팝업 */}
      {mapActionStore.actions.transport && (
        <TransportPopup
          closeTransportPopup={() => {
            mapActionStore.setMapAction('transport', false);
          }}
        />
      )}

      {/* 길찾기 시작 팝업 */}
      {modalStore.openNaviModal && <NaviPopup />}
    </>
  );
};
export default Map;
