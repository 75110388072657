import { Machine } from 'types/machine/machine.type';

declare global {
  interface Window {
    dabeeo: any;
  }
}

// 모바일은 웹가는 다르게 현재위치가 변경되기 때문에 MapDraw class 에서 일괄적으로 기기 정보 저장함
class MapDraw {
  static get mapContainer() {
    return this._mapContainer;
  }

  private static _mapContainer: HTMLDivElement;

  static dabeeoMaps: any | null = null;

  static map: any = null;

  static mapData: any = null;

  static machineFloorId = '';

  static rotation = '';

  static zoom = 0; // default zoom

  static machineCoordinate = { x: 0, y: 0 };

  static pointId = '';

  static buildingId = '';

  private mapOption = {
    camera: '2D', // 카메라 타입
    graphicLibrary: '3D', // 라이브러리 타입
    floor: MapDraw.machineFloorId,
    language: 'ko',
    showPoi: true,
    spriteEnable: true,
    spriteKeepRotation: true,
    showWaterMarker: false,
    framerate: 30,
    controlOption: {
      rotate: Number(MapDraw.rotation),
      zoom: MapDraw.zoom,
      tilt: 0,
    },

    enablePoiCollisionTest: false,
    dragDistance: 50,

    // DID에서 사용하려면 해당 옵션을 꺼줘야 함
    // Geo referencing 켜져있도록 사용하려면 해당 옵션 켜주고 OUTDOOR 우선으로 그려야함.
    enableGeoreferencing: false,

    backgroundImage: {
      visible: true,
    },
  };

  public static getInstance() {
    if (!this.dabeeoMaps) {
      this.dabeeoMaps = new window.dabeeo.Maps() as any;
    }

    return this.dabeeoMaps;
  }

  async showMap(mapContainer: HTMLDivElement, machine: Machine) {
    MapDraw._mapContainer = mapContainer;

    MapDraw.map = await MapDraw.getInstance().showMap(mapContainer, this.mapOption, MapDraw.mapData);

    MapDraw.map.control.setOption({
      controlRangeOption: {
        zoom: {
          min: Number(machine.point.mapMinZoom),
          max: Number(machine.point.mapMaxZoom),
        },
        rotate: Number(machine.mapPosition.rotation),
      },
      touchOption: {
        enableZoom: true,
        // TODO: Indoor Tracking 테스트 이후 다시 복구
        // enableRotate: false,
        enableRotate: true,
        enablePan: true,
        enableTilt: false,
      },
    });

    // 개발자도구 console 에서 접근 가능
    (window as any).mapDraw = MapDraw.map;
  }

  static cleanup() {
    if (MapDraw.map) {
      MapDraw.map.context.cleanup();
    }
  }
}

export default MapDraw;
