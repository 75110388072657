import { DEFAULT_IMAGE } from 'data/defaultImages';
import { useEffect, useRef } from 'react';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import useValidStore from 'store/valid';
import styles from './DetailTenantPopupBody.module.scss';
import DetailTenantCustomFields from './cutom-fields/DetailTenantCustomField';
import PocButtons from './poc-buttons/PocButtons';

const DetailTenantPopupBody = () => {
  // store
  const { currentTenant: tenant } = useTenantStore();
  const langStore = useLanguageStore();
  const validStore = useValidStore();

  // ref
  const bodyRef = useRef<HTMLDivElement>(null);

  /**
   * useEffect, init 스크롤 위치 상단으로 지정
   */
  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <div ref={bodyRef} className={styles.body_wrapper}>
      {/* 카테고리 */}
      <div className={styles.category_wrapper}>
        {tenant && tenant?.keywords.map((keyword: string) => <div className={styles.category}>{keyword}</div>)}
      </div>
      {/* 썸네일 */}
      <div className={styles.image_wrapper}>
        <div className={styles.thumbnail}>
          <img
            src={`${tenant?.subLogoUri || DEFAULT_IMAGE}`}
            alt='thumbnail'
            onError={e => {
              const el = e.target as HTMLImageElement;
              el.src = DEFAULT_IMAGE;
            }}
          />
        </div>

        {/** 위치지정, 즐겨찾기, 공유하기 버튼 영역 */}
        {/* {
          // ! 시연용 워크스페이스 분기처리
          validStore.isPocWorkspace && <PocButtons />
        } */}
      </div>

      {/* tenant 정보 */}
      {tenant && (
        <div className={styles.text_details_wrapper}>
          {/* 커스텀 필드 */}
          <DetailTenantCustomFields customFields={tenant.tenantCustomFields} />

          {/* 설명 */}
          {tenant?.description[langStore.currentLang] && (
            <div className={styles.desc_wrapper}>
              <pre className={styles.desc}>
                {tenant?.description[langStore.currentLang].replaceAll('\\n', '\n') || '-'}
              </pre>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default DetailTenantPopupBody;
