import { Fragment, useEffect, useState } from 'react';
import usePointStore from 'store/point';
import { CategoryFeature } from 'types/category/category.type';
import { TenantCategoryGroup, TenantWithPointId } from 'types/tenant/tenant.type';
import CategoryTag from '../../category/tag/CategoryTag';
import FloorName from '../../floor/FloorName';
import PointName from '../../point/PointName';
import styles from './InfoTagGroup.module.scss';

type Props = {
  tenant: TenantWithPointId | null;
  type: 'location' | 'search';
};

// floor, point, category tag
// TODO: refactor
const InfoTagGroup = ({ tenant, type }: Props) => {
  const pointStore = usePointStore();

  const [categoryGroups, setCategoryGroups] = useState<TenantCategoryGroup[] | null>(null);

  const handleCategoryGroup = () => {
    if (tenant) {
      if (type === 'location') {
        const filteredCategoryGroups = tenant.categoryGroups.filter(
          category =>
            category.feature === CategoryFeature.tenantSearch || category.feature === CategoryFeature.mapFacility,
        );

        setCategoryGroups(filteredCategoryGroups);
      }

      if (type === 'search') {
        const filteredCategoryGroups = tenant.categoryGroups.filter(
          category => category.feature === CategoryFeature.tenantSearch,
        );

        setCategoryGroups(filteredCategoryGroups);
      }
    }
  };

  useEffect(() => {
    handleCategoryGroup();
  }, []);

  if (!tenant) {
    return <> </>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.floor_and_point}>
        <FloorName tenant={tenant} />
        {pointStore.points.length > 1 && (
          <>
            <div className={styles.bar} />
            <PointName tenant={tenant} />
          </>
        )}
      </div>

      <div className={styles.categories}>
        {/* // TODO: category 중복 확인 */}
        {/* 다중키워드일 경우 최대 3개 노출 */}
        {categoryGroups?.map(
          (category, idx: number) =>
            idx < 3 && (
              <Fragment key={category.id}>
                <CategoryTag name={category.name} />
              </Fragment>
            ),
        )}
      </div>
    </div>
  );
};

export default InfoTagGroup;
