import InfoGroup from 'components/common/info/group/basic/InfoGroup';
import { ICONS } from 'constants/icons';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import CloseButton from '../../close/CloseButton';
import styles from './DetailTenantPopupHeader.module.scss';

type DetailTenantPopupHeaderProps = {
  closeDetailPopup: () => void;
};

const DetailTenantPopupHeader = ({ closeDetailPopup }: DetailTenantPopupHeaderProps) => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  if (!tenantStore.currentTenant) {
    return <> </>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header_text_row}>
        <div className={styles.header_text_wrapper}>
          <div className={styles.title}>{tenantStore.currentTenant?.name[langStore.currentLang]}</div>
          <InfoGroup />
        </div>
        <div onClick={closeDetailPopup}>
          <img src={ICONS.CLOSE_BLACK} alt='상세 팝업 닫기 버튼' />
        </div>
      </div>
    </div>
  );
};
export default DetailTenantPopupHeader;
