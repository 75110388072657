import { changeCamera } from 'map/control/camera';
import MapDraw from 'map/MapDraw';
import { RefObject, useEffect } from 'react';
import useLanguageStore from 'store/languages';
import useLoadingStore from 'store/loading';
import useMachineStore from 'store/machine';
import useTrackingStore from 'store/tracking';
import { errorConsole } from 'util/common/console';
import { drawMyLocationByTrackingType } from 'util/common/mapCommon';
import { changeLangCodeForMap } from 'util/multi-lang/convertLangCodeForMap';

type Props = {
  mapRef: RefObject<HTMLDivElement>;
};

const useMapAppend = ({ mapRef }: Props) => {
  const machineStore = useMachineStore();
  const langStore = useLanguageStore();
  const loadingStore = useLoadingStore();
  const trackingStore = useTrackingStore();

  // const [rotation, setRotation] = useState<number>(0);

  /**
   * 지도 회전 시 발생하는 이벤트
   * @param e rotation-changed event
   */
  // ! 지도 회전 제거
  // const onRotationChanged = (e: any) => {
  //   trackingStore.setMapRotation(Math.floor(e.detail.changedValue));
  // };

  // 지도 append
  const appendMap = async () => {
    try {
      if (machineStore.machine) {
        if (mapRef.current) {
          const map = mapRef.current;

          await new MapDraw().showMap(map, machineStore.machine);

          if (trackingStore.trackingType === 'FIXED') {
            // MapDraw.rotation = String(trackingStore.mapRotation);
            changeCamera({ rotation: trackingStore.mapRotation, transition: true });
          }

          // ! 지도 회전 제거
          // map.addEventListener('rotation-changed', onRotationChanged);

          // 내 층일 경우만 gps 켜주기
          await drawMyLocationByTrackingType(trackingStore.trackingType, MapDraw.machineCoordinate);

          changeLangCodeForMap(langStore.currentLang);
        }
      }
    } catch (e) {
      errorConsole(`showMap error : ${e}`);
    } finally {
      loadingStore.setLoadingMap(false);
    }
  };

  useEffect(() => {
    if (loadingStore.hasMapData) {
      if (mapRef) {
        appendMap();
      }
    }
  }, [loadingStore.hasMapData, mapRef]);

  // cleanup custom event
  // ! 지도 회전 제거
  // useEffect(() => {
  //   if (mapRef.current) {
  //     if (trackingStore.trackingType === 'ON') {
  //       MapDraw.mapContainer.addEventListener('rotation-changed', onRotationChanged);
  //     }
  //   }
  //   return () => {
  //     if (mapRef.current) {

  //       MapDraw.mapContainer.removeEventListener('rotation-changed', onRotationChanged);
  //     }
  //   };
  // }, [trackingStore.trackingType]);

  useEffect(() => {
    return () => {
      if (trackingStore.trackingType === 'OFF') {
        trackingStore.setMapRotation(Number(machineStore.machine?.mapPosition.rotation));
      }
    };
  }, []);

  // return { rotation };
};
export default useMapAppend;
